var initializers = []
, win = window
, doc = document
, stage = 0
, initializerRegistered = false
;

/// 現在 → DOMContentLoaded → window.onload のタイミングで window.google.maps をチェックする
function initListener() {
	if (initializerRegistered) return;
	initializerRegistered = true;
	
	if (!initAll()) {
		if (doc.addEventListener) {
			doc.addEventListener("DOMContentLoaded", documentReady, false);
			win.addEventListener("load", documentReady, false);
		}
		else if (doc.attachEvent) {
			doc.attachEvent("onreadystatechange", documentReady);
			win.attachEvent("onload", documentReady);
		}
	}
}

function documentReady() {
	if (initAll()) {
		if (doc.addEventListener) {
			doc.removeEventListener("DOMContentLoaded", documentReady, false);
			win.removeEventListener("load", documentReady, false);
		}
		else if (doc.attachEvent) {
			doc.detachEvent("onreadystatechange", documentReady);
			win.detachEvent("onload", documentReady);
		}
	}
}

/// @return initialized: Boolean
function initAll() {
	if (!win.google || !google.maps) {
		return false;
	}

	for (var i = 0; i < initializers.length; i++) {
		initializers[i](google.maps);
	}

	// すでに実行したものは除去
	initializers = [];
	
	return true;
}

function ready(initializer) {
	if (typeof initializer !== "function") {
		throw new TypeError("initializer must be a function");
	}

	// すでに Google Maps が読み込まれていれば initializer を実行
	if (win.google && google.maps) {
		initializer(google.maps);
		return;
	}
	
	for (var i = 0; i < initializers.length; i++) {
		if (initializers[i] === initializer) {
			return; // すでに登録済
		}
	}
	initializers.push(initializer);

	initListener();
}

// Expose
export default ready
