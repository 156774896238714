/* global zzl */
import axios from 'axios'

const { appBase, uploadImagePrefix } = zzl.env
const listE = document.querySelector('.ho-blog-list')
let glider = null

if (listE) {
	window.addEventListener('load', init)
	window.addEventListener('resize', resize)
	window.addEventListener('orientationchange', resize)
}

function init () {
	const frameE = listE.parentNode
	const containerE = frameE.parentNode
	;[].forEach.call(containerE.querySelectorAll('.hidden-noscript'), E => E.classList.remove('hidden-noscript'))

	glider = new Glider(listE, {
		slidesToShow: 4,
		slidesToScroll: 4,
		draggable: true,
		dots: '.dots',
		arrows: {
			prev: '.prev',
			next: '.next'
		}
	})
	resize()

	fetchRest()
}

function fetchRest () {
	axios.get(appBase + '/blog/rest.json')
	.then(response => addItems(response.data))
	.catch(err => console.warn(err))
}

function addItems (posts) {
	const template = document.getElementById('ho-blog-template');
	posts.forEach(post => {
		const item = template.cloneNode(true)
		item.id = null
		item.querySelector('a').setAttribute('href', appBase + post.url)
		if (post.image) item.querySelector('.img').style.backgroundImage = 'url(' + uploadImagePrefix + post.image + ')'
		item.querySelector('.title').textContent = post.title
		glider.addItem(item)
	})
	resize()
}

function resize () {
	const frameE = listE.parentNode
	const items = frameE.querySelectorAll('.ho-blog-item-cnt')
	if (items.length < 1) return

	const firstItem = items[0]
	frameE.style.height = firstItem.offsetHeight + 'px'
}
