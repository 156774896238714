function homeNews() {
	const select = document.getElementById('news-category-select')
	const wrap = document.getElementById('news-list-wrap')
	if (!select || !wrap) return

	const allCategoryHTML = wrap.innerHTML
	let prevTagId = ''

	select.addEventListener('change', () => {
		const tagId = select.value
		if (tagId === prevTagId) return

		if (tagId === '') {
			wrap.innerHTML = allCategoryHTML
		} else {
			getPartial(tagId, html => wrap.innerHTML = html)
		}
		prevTagId = tagId
	})
}

const cache = {}

function getPartial(tagId, callback) {
	if (tagId in cache) {
		callback(cache[tagId])
		return
	}

	fetch(`/info/tag/${tagId}.partial`)
	.then(response => response.text())
	.then(text => {
		cache[tagId] = text
		callback(text)
	})
}

export default homeNews
