/*
	Bitstream Map Replacer
	Google Static Map をインタラクティブな地図に置き換える

	<div class="bs-replace-map"
		title="（マーカーのタイトル）"
		data-position="lat,lng,map_lat,map_lng,map_zoom"
		data-style="[{"stylers":[{"saturation":-45},{"lightness":0},{"gamma":1}]}]">
		<img alt="" src="//maps.googleapis.com/...">
	</div>
 */
import domReady from './domReady'
import googleMapsReady from './googleMapsReady'

function replaceMap(E) {
	var GM = google.maps, title, positions, style, map, mapType, marker, popup;

	title = E.getAttribute("title") || null;
	E.removeAttribute("title");
	positions = (E.getAttribute("data-position") || "").split(",");
	if (!positions[4]) {
		console.warn("Invalid data-position form.");
		return;
	}

	try {
		style = JSON.parse(E.getAttribute("data-style") || "{}");
	} catch (e) {
		style = null;
	}

	map = new GM.Map(E, {
		center: new GM.LatLng(positions[2], positions[3]),
		zoom: ~~positions[4],
		minZoom: 12,
		mapTypeId: GM.MapTypeId.ROADMAP,
		mapTypeControl: false,
		zoomControl: true,
		streetViewControl: false,
		overviewMapControl: false,
		clickableIcons: false
	});

	marker = new GM.Marker({
		map: map,
		position: new GM.LatLng(positions[0], positions[1]),
		title: title || ""
	});
	
	if (title !== null) {
		popup = new GM.InfoWindow({
			content: createInfoWindowContent(title),
			noSupress: true
		});
		popup.open(map, marker);
	}

	// スタイルを設定
	if (style) {
		mapType = new GM.StyledMapType(style, {name: "My Style"});
		map.mapTypes.set("mystyle", mapType);
		map.setMapTypeId("mystyle");
	}
}

function createInfoWindowContent(title) {
	var E = document.createElement("div");
	E.className = "bs-replace-map-popup-content";
	E.textContent = title;
	E.style.minWidth = "120px";
	return E;
}

export default function googleMapsEmbed() {
	googleMapsReady(function relpaceMapInitializer() {
		domReady(function() {
			;[].forEach.call(document.querySelectorAll(".bs-replace-map"), replaceMap);
		});
	});
}
